import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import Auth from "../../../lib/auth";
import Graphql from "../../../lib/graphql";
import Utils from "../../../lib/utils";

import { Accordion, Badge, Button, Tab, Tabs } from "react-bootstrap";
import { ScaleLoader } from "react-spinners";
import Modal from "react-bootstrap/Modal";
import { CopyBlock } from "react-code-blocks";
import Queries from "../../../lib/migration/queries";

export default function MigrationDetail() {
    useEffect(() => {
        if(!Auth.groupCheck('EnvironmentAdmins') && !Auth.groupCheck('MigrationAdmins') && !Auth.groupCheck('MigrationEngineers')) {
            const error = new Error();

            error.message   = 'Not Authorized';
            error.stack     = null;
            error.httpError = 401;

            throw error;
        }
    }, []);

    const { migrationId }   = useParams();
    const navigate          = useNavigate();

    const [migration, setMigration]     = useState({});
    const [client, setClient]           = useState({});
    const [targetStack, setTargetStack] = useState({});
    const [loading, setLoading]         = useState(true);

    const [showViewSftpCredentialsModal, setShowViewSftpCredentialsModal]   = useState(false);
    const [showSelectTargetStackModal, setShowSelectTargetStackModal]       = useState(false);
    const [showEditDetailsModal, setShowEditDetailsModal]                   = useState(false);

    const [sftpUsername, setSftpUsername]   = useState(false);
    const [sftpPassword, setSftpPassword]   = useState(false);

    const [assignableStacks, setAssignableStacks] = useState([]);

    const sftpInternalEndpoint      = `sftp-${process.env.REACT_APP_LYNX_ENVIRONMENT}-us-east-2.nextgenmcs.com`;
    const sftpExternalEndpoint      = `sftp-east2-internal.nextgenmcs.com`;

    const handleCloseViewSftpCredentialsModal   = () => setShowViewSftpCredentialsModal(false);
    const handleOpenViewSftpCredentialsModal    = () => setShowViewSftpCredentialsModal(true);

    const handleCloseSelectTargetStackModal = () => setShowSelectTargetStackModal(false);
    const handleOpenSelectTargetStackModal  = () => setShowSelectTargetStackModal(true);

    const handleCloseEditDetailsModal   = () => setShowEditDetailsModal(false);
    const handleOpenEditDetailsModal    = () => setShowEditDetailsModal(true);

    const sftpInternalCodeString    = `.\\start_winscp.bat "ngcstmr${client.clientId}@${sftpInternalEndpoint}"`;
    const sftpExternalCodeString    = `.\\start_winscp.bat "ngcstmr${client.clientId}@${sftpExternalEndpoint}"`;

    useEffect(() => {
        const fetchData = async () => {
            const getMigration = `
                query GetMigration($id: ID!) {
                    getMigration(id: $id) {
                        id
                        dataMigrationRegion
                        migrationName
                        customerBaselineAMI {
                            name
                            location
                            createdAt
                            amiType
                            description
                            id
                            updatedAt
                            _version
                            _deleted
                            _lastChangedAt
                            migrationModifiedCustomerBaselineAMIId
                            __typename
                        }
                        modifiedCustomerBaselineAMI {
                            nextToken
                            startedAt
                            __typename
                        }
                        scheduledStart
                        scheduledCutover
                        customerInventory {
                            nextToken
                            startedAt
                            __typename
                        }
                        client {
                            clientId
                            clientName
                            leveraged
                            multiTimezone
                            userCount
                            timeZone
                            purchasedStoraged
                            internalSubnets
                            osVersion
                            sqlDriveLetter
                            createdAt
                            updatedAt
                            _version
                            _deleted
                            _lastChangedAt
                            __typename
                            stacks {
                                items {
                                    id
                                    stackName
                                    az1
                                    az2
                                    az1Cidr
                                    az2Cidr
                                    pod {
                                        podName
                                    }
                                }
                            }
                        }
                        terminalServers {
                            nextToken
                            startedAt
                            __typename
                        }
                        status
                        tasks {
                            nextToken
                            startedAt
                            __typename
                        }
                        useDms
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        clientMigrationsClientId
                        migrationCustomerBaselineAMIId
                        __typename
                    }
                }
            `;

            let variables = {
                filter: {
                    _deleted: {
                        ne: true
                    }
                },
                id: migrationId
            }

            return await Graphql.query(getMigration, variables).then(async (gq) => {
                // TODO - why doesn't getMigration have filter options? filtering this after the query for now...
                if (gq.getMigration._deleted === true) {
                    return {};
                }

                setClient(gq.getMigration.client);
                setTargetStack(gq.getMigration.client.stacks);
                setMigration(gq.getMigration);

                return gq.getMigration;
            });
        };

        fetchData().then(async (migration) => {
            const sftpCredentialSecret  = `/nextgen/${process.env.REACT_APP_LYNX_ENVIRONMENT}/envmgr/sftp/ngcstmr${migration.client.clientId}`;
            let creds                   = await Queries.sftpCredentials(sftpCredentialSecret, Utils.formatRegion(migration.dataMigrationRegion));

            setSftpUsername(creds[0]);
            setSftpPassword(creds[1]);

            return migration;
        }).then( async (m) => {
            let stacks = await Queries.getAssignableStack(m.client.clientId);

            setAssignableStacks(stacks);
        }).then(() => {
            setLoading(false);
        });
    }, []);

    function targetStackTable () {
        return (
            <table className="lynx-table table table-striped table-hover table-bordered">
                <thead>
                <tr>
                    <th>
                        Stack Name
                    </th>
                    <th>
                        Primary AZ
                    </th>
                    <th>
                        Primary CIDR
                    </th>
                    <th>
                        Secondary AZ
                    </th>
                    <th>
                        Secondary CIDR
                    </th>
                    <th>
                        POD Attached to Stack
                    </th>
                </tr>
                </thead>
                {targetStackTableRows(typeof targetStack.items !== "undefined" ? targetStack.items[0] : {})}
            </table>
        );
    }

    function targetStackTableRows(stack) {
        if (!stack) {
            return;
        }

        return (
            <tbody>
                <tr style={{ cursor: 'pointer' }} onClick={() => navigate(`/stacks/${stack.id}`)} key={stack.id}>
                    <td>{stack.stackName}</td>
                    <td>{stack.az1}</td>
                    <td>{stack.az1Cidr}</td>
                    <td>{stack.az2}</td>
                    <td>{stack.az2Cidr}</td>
                    <td>{stack.pod && stack.podName !== "undefined" ? stack.pod.podName : 'N/A'}</td>
                </tr>
            </tbody>
        );
    }

    function viewSftpCredentialsModal() {
        return (
            <Modal size="xl" show={showViewSftpCredentialsModal} onHide={handleCloseViewSftpCredentialsModal} backdrop="static">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h5>
                        SFTP External Credentials - Use From Client Self Hosted Servers
                    </h5>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm">
                                <div className="container border border-light-subtle">
                                    <div className="row border border-light-subtle p-1">
                                        <div className="col fw-bold">
                                            Username
                                        </div>
                                        <div className="col-sm">
                                            {sftpUsername}
                                        </div>
                                    </div>
                                    <div className="row border border-light-subtle p-1">
                                        <div className="col fw-bold">
                                            Password
                                        </div>
                                        <div className="col-sm">
                                            {sftpPassword}
                                        </div>
                                    </div>
                                    <div className="row border border-light-subtle p-1">
                                        <div className="col fw-bold">
                                            SFTP Endpoint
                                        </div>
                                        <div className="col-sm">
                                            {sftpInternalEndpoint}
                                        </div>
                                    </div>
                                    <div className="row border border-light-subtle p-3">
                                        <CopyBlock customStyle={{overflowX: "auto"}} text={sftpInternalCodeString} showLineNumbers></CopyBlock>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-5" />
                    <h5 className="mt-5">
                        SFTP Internal Credentials - Use From Within Clients NGMCS Hosted Servers
                    </h5>
                    <div className="container mb-5">
                        <div className="row">
                            <div className="col-sm">
                                <div className="container border border-light-subtle">
                                    <div className="row border border-light-subtle p-1">
                                        <div className="col fw-bold">
                                            Username
                                        </div>
                                        <div className="col-sm">
                                            {sftpUsername}
                                        </div>
                                    </div>
                                    <div className="row border border-light-subtle p-1">
                                        <div className="col fw-bold">
                                            Password
                                        </div>
                                        <div className="col-sm">
                                            {sftpPassword}
                                        </div>
                                    </div>
                                    <div className="row border border-light-subtle p-1">
                                        <div className="col fw-bold">
                                            SFTP Endpoint
                                        </div>
                                        <div className="col-sm">
                                            {sftpExternalEndpoint}
                                        </div>
                                    </div>
                                    <div className="row border border-light-subtle p-3">
                                        <CopyBlock customStyle={{overflowX: "auto"}} text={sftpExternalCodeString} showLineNumbers></CopyBlock>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    function selectTargetStackModal() {
        return (
            <Modal size="xl" show={showSelectTargetStackModal} onHide={handleCloseSelectTargetStackModal} backdrop="static" className="modal-fullscreen">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Select Target Stack
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="lynx-table table table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>
                                    Stack Name
                                </th>
                                <th>
                                    Primary AZ
                                </th>
                                <th>
                                    Primary CIDR
                                </th>
                                <th>
                                    Secondary AZ
                                </th>
                                <th>
                                    Secondary CIDR
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {assignableStacks.map(s => (
                                /*TODO (SPA-2165) - assign stack*/
                                <tr style={{ cursor: 'pointer' }} onClick={() => {Queries.assignStack().then(() => console.log('reload here')) /*window.location.reload()*/}} key={s.id}>
                                    <td>
                                        {s.stackName}
                                    </td>
                                    <td>
                                        {s.az1}
                                    </td>
                                    <td>
                                        {s.az1Cidr}
                                    </td>
                                    <td>
                                        {s.az2}
                                    </td>
                                    <td>
                                        {s.az2Cidr}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        );
    }

    function editDetailsModal() {
        return (
            <Modal show={showEditDetailsModal} onHide={handleCloseEditDetailsModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Migration
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="updateMigration" onSubmit={e => {
                        e.preventDefault();
                    }}>
                        <div className="mb-3">
                            <label>Data Migration Region</label><br/>
                            <select name="dataMigrationRegion" defaultValue={migration.dataMigrationRegion}>
                                <option value="">
                                    Please select an option
                                </option>
                                <option selected={migration.dataMigrationRegion === 'us_east_1' ? 'selected' : false} value="us_east_1">us-east-1</option>
                                <option selected={migration.dataMigrationRegion === 'us_east_2' ? 'selected' : false} value="us_east_2">us-east-2</option>
                                <option selected={migration.dataMigrationRegion === 'us_west_2' ? 'selected' : false} value="us_west_2">us-west-2</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Migration Name *</label><br/>
                            <input type="text" name="migrationName" required="required" defaultValue={migration.migrationName} />
                        </div>
                        <div className="mb-3">
                            <label>Estimated Start</label><br/>
                            <input type="date" name="scheduledStart" defaultValue={migration.scheduledStart} />
                        </div>
                        <div className="mb-3">
                            <label>Estimated Cutover</label><br/>
                            <input type="date" name="scheduledCutover" defaultValue={migration.scheduledCutover} />
                        </div>
                        <div className="mb-3">
                            <label>Status</label><br/>
                            <select name="status" defaultValue={migration.status}>
                                <option value="">Please select an option</option>
                                <option selected={migration.status === 'NotStarted' ? 'selected' : false} value="NotStarted">Not started</option>
                                <option selected={migration.status === 'InProgress' ? 'selected' : false} value="InProgress">In progress</option>
                                <option selected={migration.status === 'OnHold' ? 'selected' : false} value="OnHold">On hold</option>
                                <option selected={migration.status === 'Blocked' ? 'selected' : false} value="Blocked">Blocked</option>
                                <option selected={migration.status === 'Complete' ? 'selected' : false} value="Complete">Complete</option>
                                <option selected={migration.status === 'NotApplicable' ? 'selected' : false} value="NotApplicable">Not applicable</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Use Database Migration Service (DMS)</label><br/>
                            <div className="form-check form-switch">
                                <input type="checkbox" name="useDms" className="form-check-input" defaultChecked={migration.useDms} />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditDetailsModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmitEditDetailsModal}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function handleSubmitEditDetailsModal() {
        const form          = document.getElementById("updateMigration");
        const formData      = new FormData(form);
        const formDataObj   = {};

        formData.forEach((value, key) => formDataObj[key] = value);

        formDataObj.id                      = migration.id;
        formDataObj.dataMigrationRegion     = !formDataObj.dataMigrationRegion ? null : formDataObj.dataMigrationRegion;
        formDataObj.useDms                  = formDataObj.useDms === "on";
        formDataObj.scheduledStart          = !formDataObj.scheduledStart ? null : formDataObj.scheduledStart;
        formDataObj.scheduledCutover        = !formDataObj.scheduledCutover ? null : formDataObj.scheduledCutover;
        formDataObj._version                = migration._version

        if (!formDataObj.migrationName) {
            alert('Missing required fields');
            return false;
        }

        return Queries.updateMigration(formDataObj).then(() => window.location.reload());
    }

    return (
        <>
            <h1>
                Migration details {`${migration.migrationName ? '- ' + migration.migrationName : ''}`}
            </h1>

            <ScaleLoader loading={loading} />

            <hr />

            <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Migration Details</Accordion.Header>
                    <Accordion.Body>
                        <div className="container">
                            <div className="loading_wrapper">
                                <ScaleLoader loading={loading} />
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="container border border-light-subtle">
                                        <div className="row border border-light-subtle">
                                            <div className="col fw-bold">
                                                Client ID
                                            </div>
                                            <div className="col-sm">
                                                {client.clientId}
                                            </div>
                                        </div>
                                        <div className="row border border-light-subtle">
                                            <div className="col fw-bold">
                                                Client Name
                                            </div>
                                            <div className="col-sm">
                                                {client.clientName}
                                            </div>
                                        </div>
                                        <div className="row border border-light-subtle">
                                            <div className="col fw-bold">
                                                Estimated Start
                                            </div>
                                            <div className="col-sm">
                                                {migration.scheduledStart}
                                            </div>
                                        </div>
                                        <div className="row border border-light-subtle">
                                            <div className="col fw-bold">
                                                Estimated Cutover
                                            </div>
                                            <div className="col-sm">
                                                {migration.scheduledCutover}
                                            </div>
                                        </div>
                                        <div className="row border border-light-subtle">
                                            <div className="col fw-bold">
                                                Customer Timezone
                                            </div>
                                            <div className="col-sm">
                                                {client.timeZone}
                                            </div>
                                        </div>
                                        <div className="row border border-light-subtle">
                                            <div className="col fw-bold">
                                                Data Migration Region
                                            </div>
                                            <div className="col-sm">
                                                {Utils.formatRegion(migration.dataMigrationRegion)}
                                            </div>
                                        </div>
                                        <div className="row border border-light-subtle">
                                            <div className="col fw-bold">
                                                Use Data Migration Region Service?
                                            </div>
                                            <div className="col-sm">
                                                {migration.useDms ? 'Yes' : 'No'}
                                            </div>
                                        </div>
                                        <div className="row border border-light-subtle">
                                            <div className="col fw-bold">
                                                Pod Client is Attached to
                                            </div>
                                            <div className="col-sm">
                                                {migration.client && (typeof migration.client.stacks !== "undefined") && (typeof migration.client.stacks.items[0] !== "undefined") ? migration.client.stacks.items[0].pod.podName : 'N/A'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-5 mt-5">
                                    <div className="mw-100 clearfix">
                                        {targetStackTable()}
                                        <div className="loading_wrapper">
                                            <ScaleLoader loading={loading} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <div className="mw-100 clearfix text-end">
                <Button variant="danger" className="d-inline-block m-1">
                    Delete
                </Button>
                <Button onClick={handleOpenSelectTargetStackModal} variant="success" className="d-inline-block m-1">
                    Select Target Stack
                </Button>
                <Button onClick={handleOpenViewSftpCredentialsModal} type="button" variant="success" className="d-inline-block m-1">
                    View SFTP Credentials
                </Button>
                <Button onClick={handleOpenEditDetailsModal} variant="success" className="d-inline-block m-1">
                    Edit Details
                </Button>
                {viewSftpCredentialsModal()}
                {selectTargetStackModal()}
                {editDetailsModal()}
            </div>

            <div className="mw-100 clearfix mt-5">
                <h2>
                    Migration workflow
                </h2>

                <hr />
                <Tabs justify>
                    <Tab eventKey="1" title={<><Badge className="bg-secondary">1</Badge><p>Migration Questionnaire</p></>}>
                        <Tabs justify className="mt-3">
                            <Tab eventKey="1-1" title={<><Badge className="bg-secondary">1.1</Badge><p>Pre-Migration Assessment</p></>}>

                            </Tab>
                            <Tab eventKey="1-2" title={<><Badge className="bg-secondary">1.2</Badge><p>Assessment Update Client Details</p></>}>

                            </Tab>
                        </Tabs>
                    </Tab>
                    <Tab eventKey="2" title={<><Badge className="bg-secondary">2</Badge><p>Provision SFTP</p></>}>
                        <Tabs justify className="mt-3">
                            <Tab eventKey="2-1" title={<><Badge className="bg-secondary">2.1</Badge><p>Provision SFTP Using Service Catalog</p></>}>

                            </Tab>
                            <Tab eventKey="2-2" title={<><Badge className="bg-secondary">2.2</Badge><p>Validate Credentials</p></>}>

                            </Tab>
                        </Tabs>
                    </Tab>
                    <Tab eventKey="3" title={<><Badge className="bg-secondary">3</Badge><p>Migrate Fileshare</p></>}>
                        <Tabs justify className="mt-3">
                            <Tab eventKey="3-1" title={<><Badge className="bg-secondary">3.1</Badge><p>Setup File Migration Utils</p></>}>

                            </Tab>
                            <Tab eventKey="3-2" title={<><Badge className="bg-secondary">3.2</Badge><p>Transfer Files to S3</p></>}>

                            </Tab>
                            <Tab eventKey="3-3" title={<><Badge className="bg-secondary">3.3</Badge><p>Transfer Files from S3 to Target File Share</p></>}>

                            </Tab>
                        </Tabs>
                    </Tab>
                    <Tab eventKey="4" title={<><Badge className="bg-secondary">4</Badge><p>Migrate Databases</p></>}>
                        <Tabs justify className="mt-3">
                            <Tab eventKey="4-1" title={<><Badge className="bg-secondary">4.1</Badge><p>Create Backups</p></>}>

                            </Tab>
                            <Tab eventKey="4-2" title={<><Badge className="bg-secondary">4.2</Badge><p>Transfer Backups to S3</p></>}>

                            </Tab>
                            <Tab eventKey="4-3" title={<><Badge className="bg-secondary">4.3</Badge><p>Transfer Backups to Destination Server</p></>}>

                            </Tab>
                            <Tab eventKey="4-4" title={<><Badge className="bg-secondary">4.4</Badge><p>Restore SQL Backups</p></>}>

                            </Tab>
                            <Tab eventKey="4-5" title={<><Badge className="bg-secondary">4.5</Badge><p>Run Post-Migration Script</p></>}>

                            </Tab>
                        </Tabs>
                    </Tab>
                    <Tab eventKey="5" title={<><Badge className="bg-secondary">5</Badge><p>Deploy Stack</p></>}>
                        <Tabs justify className="mt-3">
                            <Tab eventKey="5-1" title={<><Badge className="bg-secondary">5.1</Badge><p>Create Baseline Target Account</p></>}>

                            </Tab>
                            <Tab eventKey="5-2" title={<><Badge className="bg-secondary">5.2</Badge><p>Launch Customer Stack</p></>}>

                            </Tab>
                        </Tabs>
                    </Tab>
                    <Tab eventKey="6" title={<><Badge className="bg-secondary">6</Badge><p>Configure Apps</p></>}>
                        <Tabs justify className="mt-3">
                            <Tab eventKey="6-1" title={<><Badge className="bg-secondary">6.1</Badge><p>Install Base Terminal Server Applications</p></>}>

                            </Tab>
                            <Tab eventKey="6-2" title={<><Badge className="bg-secondary">6.2</Badge><p>Install Ancillary Server Applications</p></>}>

                            </Tab>
                            <Tab eventKey="6-3" title={<><Badge className="bg-secondary">6.3</Badge><p>Create Base Terminal Image</p></>}>

                            </Tab>
                        </Tabs>
                    </Tab>
                    <Tab eventKey="7" title={<><Badge className="bg-secondary">7</Badge><p>Deploy Fleet</p></>}>
                        <Tabs justify className="mt-3">
                            <Tab eventKey="7-1" title={<><Badge className="bg-secondary">7.1</Badge><p>Update Customer Stack</p></>}>

                            </Tab>
                        </Tabs>
                    </Tab>
                    <Tab eventKey="8" title={<><Badge className="bg-secondary">8</Badge><p>Update Fileshare</p></>}>
                        <Tabs justify className="mt-3">
                            <Tab eventKey="8-1" title={<><Badge className="bg-secondary">8.1</Badge><p>Setup File Migration Utils</p></>}>

                            </Tab>
                            <Tab eventKey="8-2" title={<><Badge className="bg-secondary">8.2</Badge><p>Transfer Files to S3</p></>}>

                            </Tab>
                            <Tab eventKey="8-3" title={<><Badge className="bg-secondary">8.3</Badge><p>Transfer Files from S3 to Target File Share</p></>}>

                            </Tab>
                        </Tabs>
                    </Tab>
                    <Tab eventKey="9" title={<><Badge className="bg-secondary">9</Badge><p>Update Databases</p></>}>
                        <Tabs justify className="mt-3">
                            <Tab eventKey="9-1" title={<><Badge className="bg-secondary">9.1</Badge><p>Create Backups</p></>}>

                            </Tab>
                            <Tab eventKey="9-2" title={<><Badge className="bg-secondary">9.2</Badge><p>Transfer Backups to S3</p></>}>

                            </Tab>
                            <Tab eventKey="9-3" title={<><Badge className="bg-secondary">9.3</Badge><p>Transfer Backups to Destination Server</p></>}>

                            </Tab>
                            <Tab eventKey="9-4" title={<><Badge className="bg-secondary">9.4</Badge><p>Restore SQL Backups</p></>}>

                            </Tab>
                            <Tab eventKey="9-5" title={<><Badge className="bg-secondary">9.5</Badge><p>Run Post-Migration Script</p></>}>

                            </Tab>
                        </Tabs>
                    </Tab>
                </Tabs>
            </div>
        </>
    );
}