

import { useNavigate } from "react-router-dom";

import { ScaleLoader } from "react-spinners";
import Auth from "../../../lib/auth";

import Graphql from "../../../lib/graphql";
import Paginate from "../../../lib/paginate";
import Search from "../../../lib/search";
import Queries from "../../../lib/client/queries";
import Modals from "../../../lib/client/modals";
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {Button} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";


export default function TerminalServersClientDetails() {
    useEffect(() => {
        if(!Auth.groupCheck('EnvironmentAdmins') && !Auth.groupCheck('MigrationAdmins') && !Auth.groupCheck('MigrationEngineers')) {
            const error = new Error();

            error.message   = 'Not Authorized';
            error.stack     = null;
            error.httpError = 401;

            throw error;
        }
    }, []);

    const { clientId } = useParams();
    const [client, setClient] = useState({});
    const [instanceId, setInstanceId] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [stopModalOpen, setStopModalOpen] = useState(false)
    const [onboardClient, setOnboardClient] = useState(false);
    const [replaceModalOpen, setReplaceModalOpen] = useState(false);
    const [replicateModalOpen, setReplicateModalOpen] = useState(false);
    const [snapshotModalOpen, setSnapshotModalOpen] = useState(false);
    const [confirmPackagesModalOpen, setConfirmPackagesModalOpen] = useState(false);
    const [createDescriptionModalOpen, setCreateDescriptionModalOpen] = useState(false);
    const [state, setState] = React.useState(null);
    const [terminalServers, setTerminalServers] = useState([]);
    const [addTerminalServersModalOpen, setAddTerminalServersModalOpen] = useState(false);
    const [recipes, setRecipes] = React.useState({});
    const [migration, setMigration] = React.useState({});
    const [awsAccountId, setAWSAccountId] = React.useState();
    const [awsRegion, setAWSRegion] = React.useState();
    const [customerInventory, setCustomerInventory] = useState([]);
    const [description, setDescription] = useState('');
    const [replicatedNumber, setReplicatedNumber] = useState(0);
    const [amiList, setAMIList] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [searchInput, setSearchInput] = useState("");

    const closeConfirmPackagesModalOpen = () => {
        setConfirmPackagesModalOpen(false);
    }

    const openConfirmPackagesModalOpen = () => {
        setConfirmPackagesModalOpen(true);
    }
    const closeReplicateModal = () => {
        setReplicateModalOpen(false);
    };
    const openReplicateModal = (instanceId) => {
        setInstanceId(instanceId)
        setReplicateModalOpen(true);
    }

    const openOnboardClient = () => {
        setOnboardClient(true)
    }

    const closeOnboardClient = () => {
        setOnboardClient(false)
    }

    const [isProcessing, setIsProcessing] = useState(false); // Track if the process is running

    
    // Function to handle changes in the text box
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };



    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleOnChange = (newPageIndex) => {
        setCurrentPage(newPageIndex);
    };

    const refreshUpdateSoftwareInventory = () => {
        setState(updateSoftwareInventory(recipes));
    };


    // Define help bar styles
    const helpBarStyles = {
        position: 'fixed',
        right: 0,
        top: '50px',
        width: '300px',
        height: isExpanded ? 'auto' : '40px',
        backgroundColor: isExpanded ? '#f0f0f0' : 'initial',
        padding: '20px',
        boxShadow: isExpanded ? '0 0 10px rgba(0,0,0,0.1)' : 'initial',
        cursor: 'pointer',
        transition: 'height 0.3s ease-in-out',
        zIndex: "1000"
    };

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const filterBySearchCriteria = (searchInput) => (ts) => {
        const searchLowerCase = searchInput.toLowerCase();

        const instanceId = ts.instanceId?.toLowerCase() || '';
        const amiId = get_ami_id(ts.terminalServerAmiId)?.toLowerCase() || '';
        const amiType = get_ami_type(ts.terminalServerAmiId)?.toLowerCase() || '';
        const instanceState = ts.instanceState?.toLowerCase() || '';

        const matchesSearchCriteria =
            instanceId.includes(searchLowerCase) ||
            amiId.includes(searchLowerCase) ||
            amiType.includes(searchLowerCase) ||
            instanceState.includes(searchLowerCase);

        return matchesSearchCriteria;
    };

    // Function to toggle expansion state for Help Bar
    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    const styles = {
        mainHeader: {
            fontSize: '2.5rem',
        },
        sectionHeader: {
            fontSize: '1.2rem'
        },
    };
    

    function updateSoftwareInventory(recipes) {
        return (
            <div className="mw-100 mt-5 clearfix">
                <h2 style={styles.sectionHeader}>Update Software Inventory</h2> <hr/>
                {/* Optionally render something based on recipes-Not ready */}
                {recipes && recipes.length > 0 ? (
                    <ul>
                        {recipes.map((recipe, index) => (
                            <li key={index}>{recipe.name}</li>
                        ))}
                    </ul>
                ) : (
                    <p className="small-text">No recipes available.</p>
                )}
            </div>
        );
    }
    
    const handleYesClick = async () => {
        if (!isProcessing) {
            setIsProcessing(true); // Disable the button after first click

            // Show an alert immediately when the button is clicked
            alert('The function is being executed,PLEASE WAIT for about 30seconds');

            // Call addExistingEC2Instances function and wait for it to complete
            await addExistingEC2Instances();

            // Close the modal after the function completes
            closeOnboardClient();
            setIsProcessing(false);
        }
    };

    const addExistingEC2Instances = async () => {
        try {
            // Construct the payload the right input parameters
            const payload = {
                event_name: 'migrateclient',
                client_account_id: awsAccountId,
                region: awsRegion,
                client_id: client.clientId,
                client_name: client.clientName,
                migration_id: migration.id
            };
            
            // Define the GraphQL query
            const runEventOrchestrator = `
                query RunEventOrchestrator($payload: eventPayload) {
                    runEventOrchestrator(payload: $payload)
                }
            `;

            const variables = { payload };

            // Execute the GraphQL query
            await Graphql.query(runEventOrchestrator, variables);

            // Handle GraphQL response
           
            alert("Finding additional terminal servers. Click OK, and REFRESH the page after 1 minute.");
            closeOnboardClient();
        } catch (error) {
            console.error("Error adding client ID:", error.message || error);
            alert("An error occurred while adding terminal servers. Please try again.");
        }
    };
    
    function addExistingTsModal() {
        return (
            <Modal show={onboardClient} onHide={closeOnboardClient} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`Are you sure you wish to onboard ${clientId}?`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className="fw-bold text-danger">Warning:</span>
                    <br />If you currently have other processes running, do NOT onboard the client.<br /><br />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={isProcessing} onClick={handleYesClick}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={() => closeOnboardClient()}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    

    const does_build_basets_get_button = () => {
        const isButtonDisabled = terminalServers.some(ts => {
            const amiType = get_ami_type(ts.terminalServerAmiId);
            const instanceState = ts.instanceState;

            if (!amiType || !instanceState) {
                return false;
            }
            const isInstanceRunning = instanceState.toLowerCase() === "running";

            return amiType === 'CustomerBaseline' && isInstanceRunning

        });

        return isButtonDisabled;
    }

    function handleStepChange(e) {
        setReplicatedNumber(e);
    }
    function openReplaceModal(instanceId) {
        setInstanceId(instanceId)
        setReplaceModalOpen(true);
    }

    function closeReplaceModal() {
        setReplaceModalOpen(false);
    }

    function openStopModal(instanceId) {
        setInstanceId(instanceId);
        setStopModalOpen(true);
    }

    function get_region(region) {
        if (region) {
            return region.replaceAll("_", "-");
        }
    }

    function get_ami_type(ami_id) {
        for (let i = 0; i < amiList.length; i++) {
            if (typeof amiList[i] !== "undefined" && amiList[i].id == ami_id) {
                return amiList[i].amiType;
            }
        }
    }

    function get_ami_id(ami_id) {
        for (let i = 0; i < amiList.length; i++) {
            if (typeof amiList[i] !== "undefined" && amiList[i].id == ami_id) {
                return amiList[i].name;
            }
        }
    }

    function closeStopModal() {
        setStopModalOpen(false);
    }


    function closeSnapshotModal() {
        setSnapshotModalOpen(false);
    }


    function openSnapshotModal(instanceId) {
        setInstanceId(instanceId);
        setSnapshotModalOpen(true);
    }

    function closeAddTerminalServersModal() {
        setAddTerminalServersModalOpen(false);
    }

    function closeConfirmPackagesModal() {
        setConfirmPackagesModalOpen(false);
    }

    function openCreateDescriptionModal() {
        setCreateDescriptionModalOpen(true);
    }

    function closeCreateDescriptionModal() {
        setCreateDescriptionModalOpen(false);
    }

    function toggleAllColumns() {
        let hiddenColumnByDefault = document.getElementsByClassName('hiddenColumnByDefault');
        let toggleColumnsButton = document.getElementById('toggleColumnsButton');
        let terminalServerInfoTooltipIcon = document.getElementsByClassName('terminalServerInfoTooltipIcon');
        let terminalServerInfoTooltip = document.getElementsByClassName('terminalServerInfoTooltip');

        for (let i = 0; i < hiddenColumnByDefault.length; i++) {
            if (!hiddenColumnByDefault[i].style.display || hiddenColumnByDefault[i].style.display === 'none') {
                hiddenColumnByDefault[i].style.display = 'table-cell';
                toggleColumnsButton.innerHTML = 'Toggle Columns (Show Less)';
            } else {
                hiddenColumnByDefault[i].style.display = 'none';
                toggleColumnsButton.innerHTML = 'Toggle Columns (Show More)';
            }
        }

        for (let j = 0; j < terminalServerInfoTooltip.length; j++) {
            terminalServerInfoTooltip[j].style.display = 'none';

            if (hiddenColumnByDefault[0].style.display !== 'none') {
                terminalServerInfoTooltipIcon[j].style.display = 'none';
            } else {
                terminalServerInfoTooltipIcon[j].style.display = 'initial';
            }
        }
    }

    function toggleTerminalServerInfoTooltip(elementId) {
        let terminalServerInfoTooltipId = 'terminalServerInfoTooltip----' + elementId.split('----')[1];
        let terminalServerInfoTooltip = document.getElementById(terminalServerInfoTooltipId);
        if (!terminalServerInfoTooltip.style.display || terminalServerInfoTooltip.style.display === 'none') {
            terminalServerInfoTooltip.style.display = 'block';
        } else {
            terminalServerInfoTooltip.style.display = 'none';
        }
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const does_instance_get_buttons = (curInstanceId, curAmiId, curInstanceState) => {

        const isInstanceStopped = curInstanceState === "stopped" || curInstanceState === "STOPPED" || curInstanceState === "Stopped";

        if (get_ami_type(curAmiId) === "DeprecatedAMI") {
            return (
                <>
                    <Button style={{ margin: ".5em auto" }} onClick={() => openStopModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Stop</Button>&#160;&#160;
                </>
            )
        }
        else if (get_ami_type(curAmiId) === "CustomerBaseline") {
            return (
                <>
                    <Button style={{ margin: ".5em auto" }} onClick={() => openSnapshotModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Snapshot</Button>&#160;&#160;
                    <Button style={{ margin: ".5em auto" }} onClick={() => openStopModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Stop</Button>&#160;&#160;
                </>
            )
        }
        else if (get_ami_type(curAmiId) === "ModifyBaseline") {
            return (
                <>
                    <Button style={{ margin: ".5em auto" }} onClick={() => openReplaceModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Replace</Button>&#160;&#160;
                    <Button style={{ margin: ".5em auto" }} onClick={() => openStopModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Stop</Button>&#160;&#160;
                    <Button style={{ margin: ".5em auto" }} onClick={() => openReplicateModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Replicate</Button>
                </>
            )
        }
    }



    useEffect(() => {
        

            const fetchClientData = async () => {
                const clientQuery = `
                    query GetClient($clientId: ID!) {
                        getClient(clientId: $clientId) {
                            clientId
                            clientName
                            stacks {
                                items {
                                    pod {
                                        awsAccountId
                                        awsRegion
                                    }
                                }
                            }
                        }
                    }
                `;
                const clientVariables = { clientId };
                const clientData = await Graphql.query(clientQuery, clientVariables);
                if (clientData) {
                    setClient(clientData.getClient);
                    const stack = clientData.getClient.stacks.items;
                    if (stack.length > 0) {
                        const pod = stack[0].pod;
                        setAWSAccountId(pod.awsAccountId);
                        setAWSRegion(pod.awsRegion.replaceAll('_', '-'));
                    }
                }
            };


             // Fetch all data
            const fetchData = async () => {
                try {
                    await fetchClientData(); // Fetch client data first

                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            
            fetchData();
            
    }, [clientId]);

    return (
        <div>
                    <div>
                        <h1 style={styles.mainHeader}>{`Terminal Servers - ${client.clientName}`}</h1>
                        <hr />
                    </div>

                    <div className="mw-100 mt-5 clearfix">
                        {updateSoftwareInventory(recipes)}
                    </div>

                    <div className="mw-100 mt-5 clearfix">
                        <h2 style={styles.sectionHeader}>Deployed Terminal Servers</h2>
                        <hr />
                    </div>
                    <div style={{ textAlign: 'right', marginBottom: '1rem' }}>
                        <button type="button" className="btn btn-success d-inline-block mt-3 mb-3" onClick={openOnboardClient}>Add Existing Terminal Servers</button>
                        {addExistingTsModal()}
                    </div>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        style={{ marginBottom: '1rem', display: 'block', width: '100%' }}
                    />
                    <table className="lynx-table table table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>Instance</th>
                                <th>Build Date</th>
                                <th>Description</th>
                                <th>Region</th>
                                <th>AMI Type</th>
                                <th>AMI ID</th>
                                <th>Health</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {terminalServers.length === 0 ? (
                                <tr>
                                    <td colSpan="8" style={{ textAlign: 'center' }}>(No Results)</td>
                                </tr>
                            ) : (
                                terminalServers
                                    .filter(filterBySearchCriteria(searchInput))
                                    .map(ts => (
                                        <tr key={ts.instanceId}>
                                            <td>{ts.instanceId}</td>
                                            {/* <td>{Utils.timestampToStringFormatted(ts.buildDate)}</td> */}
                                            <td>{ts.description}</td>
                                            <td>{ts.region}</td>
                                            <td>{get_ami_type(ts.terminalServerAmiId)}</td>
                                            <td>{get_ami_id(ts.terminalServerAmiId)}</td>
                                            <td>
                                                <span style={{
                                                    color: ts.instanceState.toUpperCase() === 'RUNNING' ? 'green' : 'red',
                                                }}>
                                                    {ts.instanceState.toUpperCase()}
                                                </span>
                                            </td>
                                            <td>{does_instance_get_buttons(ts.instanceId, ts.terminalServerAmiId, ts.instanceState)}</td>
                                        </tr>
                                    ))
                            )}
                        </tbody>
                    </table>
                </div>
    );
}