import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
// import Queries from "./queries";

class Modals {
    static addPodModal(createPodModalOpen, closeCreatePodModal, handleSubmitAddPodModal) {
        return (
             <Modal show={createPodModalOpen} onHide={closeCreatePodModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Create New Pod
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="createPod" onSubmit={e => { e.preventDefault(); }}>
                        <div className="mb-3">
                            <label>Pod Name *</label><br />
                            <input type="text" name="podName" required="required" />
                        </div>
                        <div className="mb-3">
                            <label>Domain *</label><br />
                            <input type="text" name="domain" required="required"/>
                        </div>
                        
                        <div className="mb-3">
                            <label>Domain User</label><br />
                            <input type="text" name="domainUser" value="jcarter" readOnly />
                        </div>
                        
                        <div className="mb-3">
                            <label>Time Zone *</label><br />
                            <select name="timeZone" defaultValue="">
                                <option value="" >
                                     Please select an option
                                </option>
                                <option value="AT">AT</option>
                                <option value="AZ">AZ</option>
                                <option value="CT">CT</option>
                                <option value="ET">ET</option>
                                <option value="MT">MT</option>
                                <option value="PT">PT</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>PobSubId *</label><br />
                            <select name="podSubId" defaultValue="">
                                <option value="" >
                                     Please select an option
                                </option>
                                <option value="Pod0">pod0</option>
                                <option value="Pod1">pod1</option>
                                <option value="Pod2">pod2</option>
                                <option value="Pod3">pod3</option>
                                <option value="Pod4">pod4</option>
                                <option value="Pod5">pod5</option>
                                <option value="Pod6">pod6</option>
                                <option value="Pod7">pod7</option>
                                <option value="Pod8">pod8</option>
                                <option value="Pod9">pod9</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Account ID</label><br />
                            <input type="text" name="awsAccountId"/>
                        </div>
                        <div className="mb-3">
                            <label>AWS Region</label><br />
                            <input type="text" name="awsRegion"/>
                        </div>
                        <div className="mb-3">
                            <label>Multi timezone</label><br /> 
                            <div className="form-check form-switch">
                                <input 
                                    type="checkbox" 
                                    className="form-check-input" 
                                    name="multiTimezone" 
                                    id="multitimezoneToggle" 
                                />
                                <label className="form-check-label" htmlFor="multitimezoneToggle">
                                    Enable/Disable Multi timezone
                                </label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>VPC ID</label><br />
                            <input type="text" name="vpcId" placeholder="vpc-01234345abcdef"/>
                        </div>
                        <div className="mb-3">
                            <label>VPC CIDR</label><br />
                            <input 
                                type="text" 
                                name="vpcCidr" 
                                placeholder="127.0.0.0/8"
                            />
                        </div>
                       
                        <div className="mb-3">
                            <label>Security Group - File Server</label><br />
                            <input 
                                type="text" 
                                name="securityGroupFile" 
                                placeholder="sg-0a134fxged"
                            />
                        </div>
                        <div className="mb-3">
                            <label>Security Group - SQL</label><br />
                            <input 
                                type="text" 
                                name="securityGroupSql" 
                                placeholder="sg-0a134fxged"
                            />
                        </div>
                        <div className="mb-3">
                            <label>Security Group - Management</label><br />
                            <input 
                                type="text" 
                                name="securityGroupMgmt" 
                                placeholder="sg-0a134fxged"
                            />
                        </div>
                        <div className="mb-3">
                            <label>Security Group - Active Directory</label><br />
                            <input 
                                type="text" 
                                name="securityGroupAD" 
                                placeholder="sg-0a134fxged"
                            />
                        </div>
                        <div className="mb-3">
                            <label>Prod SQL IP Address</label><br />
                            <input 
                                type="text" 
                                name="sqlAddress" 
                                placeholder="127.0.0.1"
                            />
                        </div>
                        <div className="mb-3">
                            <label>Prod SQL Instance Name</label><br />
                            <input 
                                type="text" 
                                name="sqlName" 
                                placeholder="default"
                            />
                        </div>
                        <div className="mb-3">
                            <label>Prod SQL Availability Zone</label><br />
                            <select name="sqlAz" defaultValue="">
                                <option value="" >
                                     Please select an option
                                </option>
                                <option value="us_east_1a">us-east-1a</option>
                                <option value="us_east_1b">us-east-1b</option>
                                <option value="us_east_1c">us-east-1c</option>
                                <option value="us_east_1d">us-east-1d</option>
                                <option value="us_east_1e">us-east-1e</option>
                                <option value="us_east_1f">us-east-1f</option>
                                <option value="us_east_2a">us-east-2a</option>
                                <option value="us_east_2b">us-east-2b</option>
                                <option value="us_east_2c">us-east-2c</option>
                                <option value="us_west_2a">us-west-2a</option>
                                <option value="us_west_2b">us-west-2b</option>
                                <option value="us_west_2c">us-west-2c</option>
                                <option value="us_west_2d">us-west-2d</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Report SQL IP Address</label><br />
                            <input 
                                type="text" 
                                name="sqlReportAddress" 
                                placeholder="127.0.0.1"
                            />
                        </div>
                        <div className="mb-3">
                            <label>Report SQL Instance Name</label><br />
                            <input 
                                type="text" 
                                name="sqlReportName" 
                                placeholder="default"
                            />
                        </div>
                        <div className="mb-3">
                            <label>Report SQL Availability Zone</label><br />
                            <select name="sqlReportAz" defaultValue="">
                                <option value="" >
                                     Please select an option
                                </option>
                                <option value="us_east_1a">us-east-1a</option>
                                <option value="us_east_1b">us-east-1b</option>
                                <option value="us_east_1c">us-east-1c</option>
                                <option value="us_east_1d">us-east-1d</option>
                                <option value="us_east_1e">us-east-1e</option>
                                <option value="us_east_1f">us-east-1f</option>
                                <option value="us_east_2a">us-east-2a</option>
                                <option value="us_east_2b">us-east-2b</option>
                                <option value="us_east_2c">us-east-2c</option>
                                <option value="us_west_2a">us-west-2a</option>
                                <option value="us_west_2b">us-west-2b</option>
                                <option value="us_west_2c">us-west-2c</option>
                                <option value="us_west_2d">us-west-2d</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>File Server IP Address</label><br />
                            <input 
                                type="text" 
                                name="fileServerAddress" 
                                placeholder="127.0.0.1"
                            />
                        </div>
                        <div className="mb-3">
                            <label>File Server Name</label><br />
                            <input 
                                type="text" 
                                name="fileServerName" 
                                placeholder="default"
                            />
                        </div>
                        <div className="mb-3">
                            <label>File Server AZ</label><br />
                            <select name="fileServerAz" defaultValue="">
                                <option value="" >
                                     Please select an option
                                </option>
                                <option value="us_east_1a">us-east-1a</option>
                                <option value="us_east_1b">us-east-1b</option>
                                <option value="us_east_1c">us-east-1c</option>
                                <option value="us_east_1d">us-east-1d</option>
                                <option value="us_east_1e">us-east-1e</option>
                                <option value="us_east_1f">us-east-1f</option>
                                <option value="us_east_2a">us-east-2a</option>
                                <option value="us_east_2b">us-east-2b</option>
                                <option value="us_east_2c">us-east-2c</option>
                                <option value="us_west_2a">us-west-2a</option>
                                <option value="us_west_2b">us-west-2b</option>
                                <option value="us_west_2c">us-west-2c</option>
                                <option value="us_west_2d">us-west-2d</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Primary DNS IP</label><br />
                            <input 
                                type="text" 
                                name="dnsIpPrimary" 
                                placeholder="127.0.0.1"
                            />
                        </div>
                        <div className="mb-3">
                            <label>Secondary DNS IP</label><br />
                            <input 
                                type="text" 
                                name="dnsIpSecondary" 
                                placeholder="127.0.0.1"
                            />
                        </div>
                        <div className="mb-3">
                            <label>Transit Gateway ID</label><br />
                            <input 
                                type="text" 
                                name="tgwAttachmentId" 
                                placeholder="tgw-0123EXAMPLE"
                            />
                        </div>
                        <div className="mb-3">
                            <label>Medfusion CIDR</label><br />
                            <input 
                                type="text" 
                                name="medFusionCidr" 
                                placeholder="127.0.0.1"
                            />
                        </div>
                        <div className="mb-3">
                            <label>EC2 Key Pair</label><br />
                            <input 
                                type="text" 
                                name="instanceKeyPair" 
                                placeholder="keypair"
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeCreatePodModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmitAddPodModal}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
    static deletePodModal(pod, showDeletePodModal, handleCloseDeletePodModal, handleSubmitDeletePodModal) {
        return (
            <Modal show={showDeletePodModal} onHide={handleCloseDeletePodModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Confirm Deletion
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className="fw-bold text-danger">Are you sure you want to delete {pod.podName}?</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeletePodModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleSubmitDeletePodModal}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    static editPodModal(pod, showEditPodModal, handleCloseEditPodModal, handleSubmitEditPodModal) {
        return (
            <Modal show={showEditPodModal} onHide={handleCloseEditPodModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Pod - {pod.podName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form id="editPod" onSubmit={e => { e.preventDefault(); }}>
                        <div className="mb-3">
                            <label>Pod Name *</label><br />
                            <input type="text" name="podName" required="required" defaultValue={pod.podName} />
                        </div>
                        <div className="mb-3">
                            <label>Domain *</label><br />
                            <input type="text" name="domain" required="required" defaultValue={pod.domain}/>
                        </div>
                        
                        <div className="mb-3">
                            <label>Domain User</label><br />
                            <input type="text" name="domainUser" value="jcarter" readOnly />
                        </div>
                        
                        <div className="mb-3">
                            <label>Time Zone *</label><br />
                            <select name="timeZone" defaultValue={pod.timeZone}>
                                <option value="" >
                                     Please select an option
                                </option>
                                <option value="AT">AT</option>
                                <option value="AZ">AZ</option>
                                <option value="CT">CT</option>
                                <option value="ET">ET</option>
                                <option value="MT">MT</option>
                                <option value="PT">PT</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>PobSubId *</label><br />
                            <select name="podSubId" defaultValue={pod.podSubId}>
                                <option value="" >
                                     Please select an option
                                </option>
                                <option value="Pod0">pod0</option>
                                <option value="Pod1">pod1</option>
                                <option value="Pod2">pod2</option>
                                <option value="Pod3">pod3</option>
                                <option value="Pod4">pod4</option>
                                <option value="Pod5">pod5</option>
                                <option value="Pod6">pod6</option>
                                <option value="Pod7">pod7</option>
                                <option value="Pod8">pod8</option>
                                <option value="Pod9">pod9</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Account ID</label><br />
                            <input type="text" name="awsAccountId" defaultValue={pod.awsAccountId}/>
                        </div>
                        <div className="mb-3">
                            <label>AWS Region</label><br />
                            <input type="text" name="awsRegion" defaultValue={pod.awsRegion}/>
                        </div>
                        <div className="mb-3">
                            <label>Multi timezone</label><br /> 
                            <div className="form-check form-switch">
                                <input 
                                    type="checkbox" 
                                    className="form-check-input" 
                                    name="multiTimezone" 
                                    id="multitimezoneToggle" 
                                    defaultChecked={pod.multiTimezone}
                                />
                                <label className="form-check-label" htmlFor="multitimezoneToggle">
                                    Enable/Disable Multi timezone
                                </label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>VPC ID</label><br />
                            <input type="text" name="vpcId" placeholder="vpc-01234345abcdef" defaultValue={pod.vpcId}/>
                        </div>
                        <div className="mb-3">
                            <label>VPC CIDR</label><br />
                            <input 
                                type="text" 
                                name="vpcCidr" 
                                placeholder="127.0.0.0/8"
                                defaultValue={pod.vpcCidr}
                            />
                        </div>
                       
                        <div className="mb-3">
                            <label>Security Group - File Server</label><br />
                            <input 
                                type="text" 
                                name="securityGroupFile" 
                                placeholder="sg-0a134fxged"
                                defaultValue={pod.securityGroupFile}
                            />
                        </div>
                        <div className="mb-3">
                            <label>Security Group - SQL</label><br />
                            <input 
                                type="text" 
                                name="securityGroupSql" 
                                placeholder="sg-0a134fxged"
                                defaultValue={pod.securityGroupSql}
                            />
                        </div>
                        <div className="mb-3">
                            <label>Security Group - Management</label><br />
                            <input 
                                type="text" 
                                name="securityGroupMgmt" 
                                placeholder="sg-0a134fxged"
                                defaultValue={pod.securityGroupMgmt}
                            />
                        </div>
                        <div className="mb-3">
                            <label>Security Group - Active Directory</label><br />
                            <input 
                                type="text" 
                                name="securityGroupAD" 
                                placeholder="sg-0a134fxged"
                                defaultValue={pod.securityGroupAD}
                            />
                        </div>
                        <div className="mb-3">
                            <label>Prod SQL IP Address</label><br />
                            <input 
                                type="text" 
                                name="sqlAddress" 
                                placeholder="127.0.0.1"
                                defaultValue={pod.sqlAddress}
                            />
                        </div>
                        <div className="mb-3">
                            <label>Prod SQL Instance Name</label><br />
                            <input 
                                type="text" 
                                name="sqlName" 
                                placeholder="default"
                                defaultValue={pod.sqlName}
                            />
                        </div>
                        <div className="mb-3">
                            <label>Prod SQL Availability Zone</label><br />
                            <select name="sqlAz" defaultValue={pod.sqlAz}>
                                <option value="" >
                                     Please select an option
                                </option>
                                <option value="us_east_1a">us-east-1a</option>
                                <option value="us_east_1b">us-east-1b</option>
                                <option value="us_east_1c">us-east-1c</option>
                                <option value="us_east_1d">us-east-1d</option>
                                <option value="us_east_1e">us-east-1e</option>
                                <option value="us_east_1f">us-east-1f</option>
                                <option value="us_east_2a">us-east-2a</option>
                                <option value="us_east_2b">us-east-2b</option>
                                <option value="us_east_2c">us-east-2c</option>
                                <option value="us_west_2a">us-west-2a</option>
                                <option value="us_west_2b">us-west-2b</option>
                                <option value="us_west_2c">us-west-2c</option>
                                <option value="us_west_2d">us-west-2d</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Report SQL IP Address</label><br />
                            <input 
                                type="text" 
                                name="sqlReportAddress" 
                                placeholder="127.0.0.1"
                                defaultValue={pod.sqlReportAddress}
                            />
                        </div>
                        <div className="mb-3">
                            <label>Report SQL Instance Name</label><br />
                            <input 
                                type="text" 
                                name="sqlReportName" 
                                placeholder="default"
                                defaultValue={pod.sqlReportName}
                            />
                        </div>
                        <div className="mb-3">
                            <label>Report SQL Availability Zone</label><br />
                            <select name="sqlReportAz" defaultValue={pod.sqlReportAz}>
                                <option value="" >
                                     Please select an option
                                </option>
                                <option value="us_east_1a">us-east-1a</option>
                                <option value="us_east_1b">us-east-1b</option>
                                <option value="us_east_1c">us-east-1c</option>
                                <option value="us_east_1d">us-east-1d</option>
                                <option value="us_east_1e">us-east-1e</option>
                                <option value="us_east_1f">us-east-1f</option>
                                <option value="us_east_2a">us-east-2a</option>
                                <option value="us_east_2b">us-east-2b</option>
                                <option value="us_east_2c">us-east-2c</option>
                                <option value="us_west_2a">us-west-2a</option>
                                <option value="us_west_2b">us-west-2b</option>
                                <option value="us_west_2c">us-west-2c</option>
                                <option value="us_west_2d">us-west-2d</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>File Server IP Address</label><br />
                            <input 
                                type="text" 
                                name="fileServerAddress" 
                                placeholder="127.0.0.1"
                                defaultValue={pod.fileServerAddress}
                            />
                        </div>
                        <div className="mb-3">
                            <label>File Server Name</label><br />
                            <input 
                                type="text" 
                                name="fileServerName" 
                                placeholder="default"
                                defaultValue={pod.fileServerName}
                            />
                        </div>
                        <div className="mb-3">
                            <label>File Server AZ</label><br />
                            <select name="fileServerAz" defaultValue={pod.fileServerAz}>
                                <option value="" >
                                     Please select an option
                                </option>
                                <option value="us_east_1a">us-east-1a</option>
                                <option value="us_east_1b">us-east-1b</option>
                                <option value="us_east_1c">us-east-1c</option>
                                <option value="us_east_1d">us-east-1d</option>
                                <option value="us_east_1e">us-east-1e</option>
                                <option value="us_east_1f">us-east-1f</option>
                                <option value="us_east_2a">us-east-2a</option>
                                <option value="us_east_2b">us-east-2b</option>
                                <option value="us_east_2c">us-east-2c</option>
                                <option value="us_west_2a">us-west-2a</option>
                                <option value="us_west_2b">us-west-2b</option>
                                <option value="us_west_2c">us-west-2c</option>
                                <option value="us_west_2d">us-west-2d</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Primary DNS IP</label><br />
                            <input 
                                type="text" 
                                name="dnsIpPrimary" 
                                placeholder="127.0.0.1"
                                defaultValue={pod.dnsIpPrimary}
                            />
                        </div>
                        <div className="mb-3">
                            <label>Secondary DNS IP</label><br />
                            <input 
                                type="text" 
                                name="dnsIpSecondary" 
                                placeholder="127.0.0.1"
                                defaultValue={pod.dnsIpSecondary}
                            />
                        </div>
                        <div className="mb-3">
                            <label>Transit Gateway ID</label><br />
                            <input 
                                type="text" 
                                name="tgwAttachmentId" 
                                placeholder="tgw-0123EXAMPLE"
                                defaultValue={pod.tgwAttachmentId}
                            />
                        </div>
                        <div className="mb-3">
                            <label>Medfusion CIDR</label><br />
                            <input 
                                type="text" 
                                name="medFusionCidr" 
                                placeholder="127.0.0.1"
                                defaultValue={pod.medFusionCidr}
                            />
                        </div>
                        <div className="mb-3">
                            <label>EC2 Key Pair</label><br />
                            <input 
                                type="text" 
                                name="instanceKeyPair" 
                                placeholder="keypair"
                                defaultValue={pod.instanceKeyPair}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditPodModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmitEditPodModal}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    static createStackModal(createStackModalOpen, closeCreateStackModal, handleSubmitStackModal) {
        return (
            <Modal show={createStackModalOpen} onHide={closeCreateStackModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Create New Stack
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form id="createStack" onSubmit={e => { e.preventDefault(); }}>
                        <div className="mb-3">
                            <label>Stack Name</label><br />
                            <input type="text" name="stackName"/>
                        </div>
                        <div className="mb-3">
                            <label>Terminal Server AMI</label><br />
                            <input type="text" name="amiTermServ" />
                        </div>
                        
                        <div className="mb-3">
                            <label>Base Server AMI</label><br />
                            <input type="text" name="amiTermServBase" />
                        </div>
                        <div className="mb-3">
                            <label>Ancillary Server AMI</label><br />
                            <input type="text" name="amiAncServ" />
                        </div>
                        <div className="mb-3">
                            <label>PXP Server AMI</label><br />
                            <input type="text" name="amiPxPServ"/>
                        </div>
                        <div className="mb-3">
                            <label>Availability Zone 1</label><br />
                            <select name="az1">
                                <option value="" >
                                     Please select an option
                                </option>
                                <option value="us_east_1a">us-east-1a</option>
                                <option value="us_east_1b">us-east-1b</option>
                                <option value="us_east_1c">us-east-1c</option>
                                <option value="us_east_1d">us-east-1d</option>
                                <option value="us_east_1e">us-east-1e</option>
                                <option value="us_east_1f">us-east-1f</option>
                                <option value="us_east_2a">us-east-2a</option>
                                <option value="us_east_2b">us-east-2b</option>
                                <option value="us_east_2c">us-east-2c</option>
                                <option value="us_west_2a">us-west-2a</option>
                                <option value="us_west_2b">us-west-2b</option>
                                <option value="us_west_2c">us-west-2c</option>
                                <option value="us_west_2d">us-west-2d</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Primary CIDR</label><br />
                            <input type="text" name="az1Cidr" placeholder="127.0.0.1"/>
                        </div>
                        <div className="mb-3">
                            <label>Availability Zone 2</label><br />
                            <select name="az2">
                                <option value="" >
                                     Please select an option
                                </option>
                                <option value="us_east_1a">us-east-1a</option>
                                <option value="us_east_1b">us-east-1b</option>
                                <option value="us_east_1c">us-east-1c</option>
                                <option value="us_east_1d">us-east-1d</option>
                                <option value="us_east_1e">us-east-1e</option>
                                <option value="us_east_1f">us-east-1f</option>
                                <option value="us_east_2a">us-east-2a</option>
                                <option value="us_east_2b">us-east-2b</option>
                                <option value="us_east_2c">us-east-2c</option>
                                <option value="us_west_2a">us-west-2a</option>
                                <option value="us_west_2b">us-west-2b</option>
                                <option value="us_west_2c">us-west-2c</option>
                                <option value="us_west_2d">us-west-2d</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Secondary CIDR</label><br />
                            <input type="text" name="az2Cidr" placeholder="127.0.0.1"/>
                        </div>
                        <div className="mb-3">
                            <label>Dns ip secondary</label><br />
                            <input 
                                type="text" 
                                name="dnsIpSecondary"
                                defaultValue=""
                            />
                        </div>
                        <div className="mb-3">
                            <label>Tgw attachment id</label><br />
                            <input 
                                type="text" 
                                name="tgwAttachmentId" 
                            
                            />
                        </div>
                        <div className="mb-3">
                            <label>Med fusion cidr</label><br />
                            <input 
                                type="text" 
                                name="medFusionCidr" 
                            />
                        </div>
                        <div className="mb-3">
                            <label>Create base term server</label><br /> 
                            <div className="form-check form-switch">
                                <input 
                                    type="checkbox" 
                                    className="form-check-input" 
                                    name="createBaseTermServer" 
                                    id="baseServerToggle"
                                    
                                />
                                <label className="form-check-label" htmlFor="multitimezoneToggle">
                            
                                </label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Create pxp server</label><br /> 
                            <div className="form-check form-switch">
                                <input 
                                    type="checkbox" 
                                    className="form-check-input" 
                                    name="createPxpServer" 
                                    id="pxpServerToggle" 
                                    
                                />
                                <label className="form-check-label" htmlFor="multitimezoneToggle">
                                    
                                </label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Terminal Server Instance Type</label><br />
                            <input type="text" name="instanceTypeTermServ" placeholder="t3.medium"/>
                        </div>           
                            
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeCreateStackModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmitStackModal}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Modals;