import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import Auth from "../../../lib/auth";
import Graphql from "../../../lib/graphql";

import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { ScaleLoader } from "react-spinners";

export default function StackDetail() {
    useEffect(() => {
        if(!Auth.groupCheck('EnvironmentAdmins') && !Auth.groupCheck('MigrationAdmins') && !Auth.groupCheck('MigrationEngineers')) {
            const error = new Error();

            error.message   = 'Not Authorized';
            error.stack     = null;
            error.httpError = 401;

            throw error;
        }
    }, []);

    const { stackId }   = useParams();
    const navigate      = useNavigate();

    const [loading, setLoading] = useState(true);
    const [stack, setStack]     = useState({});
    
    const [showDeleteStackModal, setShowDeleteStackModal]   = useState(false);
    const [showEditStackModal, setShowEditStackModal]       = useState(false);

    const handleCloseDeleteStackModal   = () => setShowDeleteStackModal(false);
    const handleShowDeleteStackModal    = () => setShowDeleteStackModal(true);
    const handleCloseEditStackModal     = () => setShowEditStackModal(false);
    const handleShowEditStackModal      = () => setShowEditStackModal(true);

    useEffect(() => {
        const fetchData = async () => {
            const getStack   = `
                query GetStack($id: ID!) {
                    getStack(id: $id) {
                      id
                      stackName
                      amiTermServ
                      amiTermServBase
                      amiAncServ
                      amiPxPServ
                      az1
                      az1Cidr
                      az2
                      az2Cidr
                      dnsIpSecondary
                      tgwAttachmentId
                      medFusionCidr
                      createBaseTermServer
                      createPxpServer
                      instanceTypeTermServ
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      clientStacksClientId
                      podStacksId
                      __typename
                    }
                  }
            `;

            let variables = {
                filter: {
                    _deleted: {
                        ne: true
                    }
                },
                id: stackId
            }

            await Graphql.query(getStack, variables).then(async (gq) => {
                // TODO - why doesn't getStack have filter options? filtering this after the query for now...
                if (gq.getStack._deleted === true) {
                    return {};
                }

                return setStack(gq.getStack);
            });
        };

        fetchData().then(() => setLoading(false));
    }, []);

    function handleSubmitDeleteStackModal() {
        return deleteStack(stack).then(() => navigate('/stacks'));
    }

    function handleSubmitEditStackModal() {
        const form          = document.getElementById("editStack");
        const formData      = new FormData(form);
        const formDataObj   = {};

        formData.forEach((value, key) => formDataObj[key] = value);

        formDataObj.id                      = stack.id;
        formDataObj.createBaseTermServer    = formDataObj.createBaseTermServer === "on";
        formDataObj.createPxpServer         = formDataObj.createPxpServer === "on";
        formDataObj.az1                     = !formDataObj.az1 ? null : formDataObj.az1;
        formDataObj.az2                     = !formDataObj.az2 ? null : formDataObj.az2;
        formDataObj._version                = stack._version

        if (!formDataObj.stackName) {
            alert('Missing required fields');
            return false;
        }

        return editStack(formDataObj).then(() => window.location.reload());
    }

    function deleteStack(stack) {
        const pushData = async() => {
            const deleteStack = `
                mutation DeleteStack(
                    $input: DeleteStackInput!
                    $condition: ModelStackConditionInput
                ) {
                    deleteStack(input: $input, condition: $condition) {
                        id
                    }
                }
            `;

            let variables = {
                input: {
                    id: stack.id,
                    _version: stack._version
                }
            }

            return await Graphql.query(deleteStack, variables);
        }

        return pushData();
    }

    function editStack(data) {
        const pushData = async () => {
            const updateStack  = `
                mutation UpdateStack(
                    $input: UpdateStackInput!
                    $condition: ModelStackConditionInput
                ) {
                      updateStack(input: $input, condition: $condition) {
                          id
                      }
                  }
            `;

            let variables = {
                input: data,
            }

            return await Graphql.query(updateStack, variables);
        }

        return pushData();
    }

    function deleteStackModal() {
        return (
            <Modal show={showDeleteStackModal} onHide={handleCloseDeleteStackModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Confirm Deletion
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className="fw-bold text-danger">Are you sure you want to delete {stack.stackName}?</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteStackModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleSubmitDeleteStackModal}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function editStackModal() {
        return (
            <Modal show={showEditStackModal} onHide={handleCloseEditStackModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Stack {stack.stackName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="editStack" onSubmit={e => { e.preventDefault(); }}>
                        <div className="mb-3">
                            <label>Stack Name *</label><br />
                            <input type="text" name="stackName" required="required" defaultValue={stack.stackName}/>
                        </div>
                        <div className="mb-3">
                            <label>Terminal Server AMI</label><br />
                            <input type="text" name="amiTermServ" defaultValue={stack.amiTermServ} />
                        </div>
                        <div className="mb-3">
                            <label>Base Server AMI</label><br />
                            <input type="text" name="amiTermServBase" defaultValue={stack.amiTermServBase} />
                        </div>
                        <div className="mb-3">
                            <label>Ancillary Server AMI</label><br />
                            <input type="text" name="amiAncServ" defaultValue={stack.amiAncServ} />
                        </div>
                        <div className="mb-3">
                            <label>PXP Server AMI</label><br />
                            <input type="text" name="amiPxPServ" defaultValue={stack.amiPxPServ} />
                        </div>
                        <div className="mb-3">
                            <label>Availability Zone 1</label><br />
                            <select name="az1">
                                <option value="">Please select an option</option>
                                <option selected={stack.az1 === 'us_east_1a' ? 'selected' : false} value="us_east_1a">us-east-1a</option>
                                <option selected={stack.az1 === 'us_east_1b' ? 'selected' : false} value="us_east_1b">us-east-1b</option>
                                <option selected={stack.az1 === 'us_east_1c' ? 'selected' : false} value="us_east_1c">us-east-1c</option>
                                <option selected={stack.az1 === 'us_east_1d' ? 'selected' : false} value="us_east_1d">us-east-1d</option>
                                <option selected={stack.az1 === 'us_east_1e' ? 'selected' : false} value="us_east_1e">us-east-1e</option>
                                <option selected={stack.az1 === 'us_east_1f' ? 'selected' : false} value="us_east_1f">us-east-1f</option>
                                <option selected={stack.az1 === 'us_east_2a' ? 'selected' : false} value="us_east_2a">us-east-2a</option>
                                <option selected={stack.az1 === 'us_east_2b' ? 'selected' : false} value="us_east_2b">us-east-2b</option>
                                <option selected={stack.az1 === 'us_east_2c' ? 'selected' : false} value="us_east_2c">us-east-2c</option>
                                <option selected={stack.az1 === 'us_west_2a' ? 'selected' : false} value="us_west_2a">us-west-2a</option>
                                <option selected={stack.az1 === 'us_west_2b' ? 'selected' : false} value="us_west_2b">us-west-2b</option>
                                <option selected={stack.az1 === 'us_west_2c' ? 'selected' : false} value="us_west_2c">us-west-2c</option>
                                <option selected={stack.az1 === 'us_wast_2d' ? 'selected' : false} value="us_west_2d">us-west-2d</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Primary CIDR</label><br />
                            <input type="text" name="az1Cidr" defaultValue={stack.az1Cidr} />
                        </div>
                        <div className="mb-3">
                            <label>Availability Zone 2</label><br />
                            <select name="az2">
                                <option value="">Please select an option</option>
                                <option selected={stack.az2 === 'us_east_1a' ? 'selected' : false} value="us_east_1a">us-east-1a</option>
                                <option selected={stack.az2 === 'us_east_1b' ? 'selected' : false} value="us_east_1b">us-east-1b</option>
                                <option selected={stack.az2 === 'us_east_1c' ? 'selected' : false} value="us_east_1c">us-east-1c</option>
                                <option selected={stack.az2 === 'us_east_1d' ? 'selected' : false} value="us_east_1d">us-east-1d</option>
                                <option selected={stack.az2 === 'us_east_1e' ? 'selected' : false} value="us_east_1e">us-east-1e</option>
                                <option selected={stack.az2 === 'us_east_1f' ? 'selected' : false} value="us_east_1f">us-east-1f</option>
                                <option selected={stack.az2 === 'us_east_2a' ? 'selected' : false} value="us_east_2a">us-east-2a</option>
                                <option selected={stack.az2 === 'us_east_2b' ? 'selected' : false} value="us_east_2b">us-east-2b</option>
                                <option selected={stack.az2 === 'us_east_2c' ? 'selected' : false} value="us_east_2c">us-east-2c</option>
                                <option selected={stack.az2 === 'us_west_2a' ? 'selected' : false} value="us_west_2a">us-west-2a</option>
                                <option selected={stack.az2 === 'us_west_2b' ? 'selected' : false} value="us_west_2b">us-west-2b</option>
                                <option selected={stack.az2 === 'us_west_2c' ? 'selected' : false} value="us_west_2c">us-west-2c</option>
                                <option selected={stack.az2 === 'us_wast_2d' ? 'selected' : false} value="us_west_2d">us-west-2d</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Secondary CIDR</label><br />
                            <input type="text" name="az2Cidr" defaultValue={stack.az2Cidr} />
                        </div>
                        <div className="mb-3">
                            <label>TGW attachment ID</label><br />
                            <input type="text" name="tgwAttachmentId" defaultValue={stack.tgwAttachmentId} />
                        </div>
                        <div className="mb-3">
                            <label>TGW attachment ID</label><br />
                            <input type="text" name="medFusionCidr" defaultValue={stack.medFusionCidr} />
                        </div>
                        <div className="mb-3">
                            <label>Create Base Terminal Server</label><br />
                            <div className="form-check form-switch">
                                <input type="checkbox" name="createBaseTermServer" className="form-check-input" defaultChecked={stack.createBaseTermServer} />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Create PXP Server</label><br />
                            <div className="form-check form-switch">
                                <input type="checkbox" name="createPxpServer" className="form-check-input" defaultChecked={stack.createPxpServer} />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Terminal Server Instance Type</label><br />
                            <input type="text" name="instanceTypeTermServ" defaultValue={stack.instanceTypeTermServ} />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditStackModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmitEditStackModal}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function stackDetails() {
        return (
            <div className="container">
                <div className="loading_wrapper">
                    <ScaleLoader loading={loading} />
                </div>
                <div className="row">
                    <div className="col-sm">
                        <div className="container border border-light-subtle">
                            <div className="row border border-light-subtle">
                                <div className="col fw-bold">
                                    Stack Name
                                </div>
                                <div className="col-sm">
                                    {stack.stackName}
                                </div>
                            </div>
                            <div className="row border border-light-subtle">
                                <div className="col fw-bold">
                                    Stack ID
                                </div>
                                <div className="col-sm">
                                    {stack.id}
                                </div>
                            </div>
                            <div className="row border border-light-subtle">
                                <div className="col fw-bold">
                                    Primary AZ
                                </div>
                                <div className="col-sm">
                                    {stack.az1}
                                </div>
                            </div>
                            <div className="row border border-light-subtle">
                                <div className="col fw-bold">
                                    Primary CIDR
                                </div>
                                <div className="col-sm">
                                    {stack.az1Cidr}
                                </div>
                            </div>
                            <div className="row border border-light-subtle">
                                <div className="col fw-bold">
                                    Secondary AZ
                                </div>
                                <div className="col-sm">
                                    {stack.az2}
                                </div>
                            </div>
                            <div className="row border border-light-subtle">
                                <div className="col fw-bold">
                                    Secondary CIDR
                                </div>
                                <div className="col-sm">
                                    {stack.az2Cidr}
                                </div>
                            </div>
                            <div className="row border border-light-subtle">
                                <div className="col fw-bold">
                                    Terminal Server Instance Type
                                </div>
                                <div className="col-sm">
                                    {stack.instanceTypeTermServ}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col text-end">
                        <button onClick={handleShowDeleteStackModal} type="button" className="btn btn-light d-inline-block m-1">Delete</button>
                        <button onClick={handleShowEditStackModal} type="button" className="btn btn-success d-inline-block m-1">Edit Details</button>
                        {deleteStackModal()}
                        {editStackModal()}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <h1>
                Stack details - {stack.stackName}
            </h1>
            <hr />
            {stackDetails()}
        </>
    );
}