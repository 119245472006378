import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Graphql from "../../../lib/graphql";
import { ScaleLoader } from "react-spinners";
import Queries from "../../../lib/client/queries";
import Modals from "../../../lib/client/modals";
import Auth from "../../../lib/auth";

export default function ClientDetail() {
    useEffect(() => {
        if(!Auth.groupCheck('EnvironmentAdmins') && !Auth.groupCheck('MigrationAdmins') && !Auth.groupCheck('MigrationEngineers')) {
            const error = new Error();

            error.message   = 'Not Authorized';
            error.stack     = null;
            error.httpError = 401;

            throw error;
        }
    }, []);

    const { clientId }  = useParams();
    const navigate      = useNavigate();

    const [loading, setLoading]                 = useState(true);
    const [client, setClient]                   = useState({});
    const [stacks, setStacks]                   = useState([]);
    const [availableStacks, setAvailableStacks] = useState([])
    const [migrations, setMigrations]           = useState([]);

    const [showDeleteClientModal, setShowDeleteClientModal]         = useState(false);
    const [showEditClientModal, setShowEditClientModal]             = useState(false);
    const [showAssignStackModal, setShowAssignStackModal]           = useState(false);
    const [showCreateMigrationModal, setShowCreateMigrationModal]   = useState(false);

    const handleCloseDeleteClientModal      = () => setShowDeleteClientModal(false);
    const handleShowDeleteClientModal       = () => setShowDeleteClientModal(true);
    const handleCloseEditClientModal        = () => setShowEditClientModal(false);
    const handleShowEditClientModal         = () => setShowEditClientModal(true);
    const handleCloseAssignStackModal       = () => setShowAssignStackModal(false);
    const handleShowAssignStackModal        = () => setShowAssignStackModal(true);
    const handleCloseCreateMigrationModal   = () => setShowCreateMigrationModal(false);
    const handleShowCreateMigrationModal    = () => setShowCreateMigrationModal(true);

    useEffect(() => {
        const fetchData = async () => {
            const getClient   = `
                query GetClient($clientId: ID!) {
                  getClient(clientId: $clientId) {
                    clientId
                    clientName
                    leveraged
                    multiTimezone
                    userCount
                    timeZone
                    purchasedStoraged
                    internalSubnets
                    osVersion
                    sqlDriveLetter
                    stacks {
                      items {
                        id
                        stackName
                        az1
                        az1Cidr
                        az2
                        az2Cidr
                      }
                    }
                    migrations {
                      items {
                        id
                        migrationName
                        scheduledStart
                        scheduledCutover
                      }
                    }
                    _version
                    _deleted
                  }
                }
            `;

            let variables = {
                filter: {
                    _deleted: {
                        ne: true
                    }
                },
                clientId: clientId
            }

            await Graphql.query(getClient, variables).then(async (gq) => {
                // TODO - why doesn't getClient have filter options? filtering this after the query for now...
                if (gq.getClient._deleted === true) {
                    return {};
                }

                // changed this to avoid this TypeError: Cannot read properties of null (reading 'join')
                gq.getClient.internalSubnetsEditor = (gq.getClient.internalSubnets || []).join('\n'); 
                gq.getClient.internalSubnetsDisplay = (gq.getClient.internalSubnets || []).join(', ');

                setStacks(gq.getClient.stacks.items);
                setMigrations(gq.getClient.migrations.items);

                return setClient(gq.getClient);
            });
        };

        fetchData().then(async () => {
            let availableStacks = await Queries.getAvailableStacks();
            setAvailableStacks(availableStacks);
        }).then(() => {
            setLoading(false);
        });
    }, []);

    function handleSubmitDeleteClientModal() {
        return Queries.deleteClient(client.clientId, client._version).then(() => navigate('/clients'));
    }

    function handleSubmitEditClientModal() {
        const form          = document.getElementById("editClient");
        const formData      = new FormData(form);
        const formDataObj   = {};

        formData.forEach((value, key) => formDataObj[key] = value);

        formDataObj.clientId            = client.clientId;
        formDataObj.leveraged           = formDataObj.leveraged === "on";
        formDataObj.multiTimezone       = formDataObj.multiTimezone === "on";
        formDataObj.internalSubnets     = formDataObj.internalSubnets.split('\n');
        formDataObj.userCount           = !formDataObj.userCount ? 0 : formDataObj.userCount;
        formDataObj.purchasedStoraged   = !formDataObj.purchasedStoraged ? 0 : formDataObj.purchasedStoraged;
        formDataObj._version            = client._version

        if (!formDataObj.clientId || !formDataObj.clientName) {
            alert('Missing required fields');
            return false;
        }

        return Queries.editClient(formDataObj).then(() => window.location.reload());
    }

    function handleSubmitCreateMigrationModal() {
        const form          = document.getElementById("createMigration");
        const formData      = new FormData(form);
        const formDataObj   = {};

        formData.forEach((value, key) => formDataObj[key] = value);

        formDataObj.clientMigrationsClientId    = client.clientId;
        formDataObj.useDms                      = formDataObj.useDms === "on";
        formDataObj.scheduledStart              = !formDataObj.scheduledStart ? null : formDataObj.scheduledStart;
        formDataObj.scheduledCutover            = !formDataObj.scheduledCutover ? null : formDataObj.scheduledCutover;
        formDataObj._version                    = client._version

        if (!formDataObj.migrationName) {
            alert('Missing required fields');
            return false;
        }

        return Queries.createMigration(formDataObj).then(() => window.location.reload());
    }

    function clientDetails() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm">
                        <div className="container border border-light-subtle">
                            <div className="row border border-light-subtle">
                                <div className="col fw-bold">
                                    Client ID
                                </div>
                                <div className="col-sm">
                                    {client.clientId}
                                </div>
                            </div>
                            <div className="row border border-light-subtle">
                                <div className="col fw-bold">
                                    Client Name
                                </div>
                                <div className="col-sm">
                                    {client.clientName}
                                </div>
                            </div>
                            <div className="row border border-light-subtle">
                                <div className="col fw-bold">
                                    Leveraged
                                </div>
                                <div className="col-sm">
                                    {client.leveraged ? 'Yes' : 'No'}
                                </div>
                            </div>
                            <div className="row border border-light-subtle">
                                <div className="col fw-bold">
                                    Multi-timezone
                                </div>
                                <div className="col-sm">
                                    {client.multiTimezone ? 'Yes' : 'No'}
                                </div>
                            </div>
                            <div className="row border border-light-subtle">
                                <div className="col fw-bold">
                                    User Count
                                </div>
                                <div className="col-sm">
                                    {client.userCount}
                                </div>
                            </div>
                            <div className="row border border-light-subtle">
                                <div className="col fw-bold">
                                    Time Zone
                                </div>
                                <div className="col-sm">
                                    {client.timeZone}
                                </div>
                            </div>
                            <div className="row border border-light-subtle">
                                <div className="col fw-bold">
                                    Purchased Storage
                                </div>
                                <div className="col-sm">
                                    {client.purchasedStoraged}
                                </div>
                            </div>
                            <div className="row border border-light-subtle">
                                <div className="col fw-bold">
                                    Internal Subnets
                                </div>
                                <div className="col-sm">
                                    {client.internalSubnetsDisplay}
                                </div>
                            </div>
                            <div className="row border border-light-subtle">
                                <div className="col fw-bold">
                                    OS
                                </div>
                                <div className="col-sm">
                                    {client.osVersion}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col text-end">
                        <button onClick={handleShowDeleteClientModal} type="button" className="btn btn-light d-inline-block m-1">Delete Client</button>
                        <button onClick={handleShowEditClientModal} type="button" className="btn btn-success d-inline-block m-1">Edit Client</button>
                        {Modals.deleteClientModal(client, showDeleteClientModal, handleCloseDeleteClientModal, handleSubmitDeleteClientModal)}
                        {Modals.editClientModal(client, showEditClientModal, handleCloseEditClientModal, handleSubmitEditClientModal)}
                    </div>
                </div>
            </div>
        );
    }

    function stacksSection() {
        return(
            <>
                <div className={'mw-100 clearfix text-end float-end' + (availableStacks <= 0 ? ' not-allowed' : '')}>
                    <button type="button" className="btn btn-success d-inline-block mt-3 mb-3" disabled={availableStacks.length <= 0} onClick={() => handleShowAssignStackModal()}>Assign Stack</button>
                </div>
                <table className="lynx-table table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th>
                                Stack Name
                            </th>
                            <th>
                                Primary AZ
                            </th>
                            <th>
                                Primary CIDR
                            </th>
                            <th>
                                Secondary AZ
                            </th>
                            <th>
                                Secondary CIDR
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {stacks.map(s => (
                            <tr style={{ cursor: 'pointer' }} onClick={() => navigate(`/stacks/${s.id}`)} key={s.id}>
                                <td>
                                    {s.stackName}
                                </td>
                                <td>
                                    {s.az1}
                                </td>
                                <td>
                                    {s.az1Cidr}
                                </td>
                                <td>
                                    {s.az2}
                                </td>
                                <td>
                                    {s.az2Cidr}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        );
    }

    function migrationsSection() {
        return(
            <>
                <div className="mw-100 clearfix text-end">
                    <button type="button" className="btn btn-success d-inline-block mt-3 mb-3" onClick={() => handleShowCreateMigrationModal()}>Create Migration</button>
                </div>
                <table className="lynx-table table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th>
                                Migration Name
                            </th>
                            <th>
                                Target Stack ID
                            </th>
                            <th>
                                Start Date
                            </th>
                            <th>
                                Cutover Date
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {migrations.map(m => (
                        <tr style={{ cursor: 'pointer' }} onClick={() => navigate(`/migrations/${m.id}`)} key={m.id}>
                            <td>
                                {m.migrationName}
                            </td>
                            <td>
                                {m.targetStackId}
                            </td>
                            <td>
                                {m.scheduledStart}
                            </td>
                            <td>
                                {m.scheduledCutover}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        );
    }

    return (
        <>
            <h1>
                {client.clientName}
            </h1>

            <hr />

            <div className="mw-100 clearfix">
                {clientDetails()}
                <div className="loading_wrapper">
                    <ScaleLoader loading={loading} />
                </div>
            </div>
            <div className="mw-100 mt-5 clearfix">
                <h2>Stacks</h2>
                <hr />
                {stacksSection()}
                {Modals.assignStackModal(client.clientId, availableStacks, showAssignStackModal, handleCloseAssignStackModal)}
                <div className="loading_wrapper">
                    <ScaleLoader loading={loading} />
                </div>
            </div>
            <div className="mw-100 mt-5 clearfix">
                <h2>Migrations</h2>
                <hr />
                {migrationsSection()}
                {Modals.createMigrationModal(clientId, showCreateMigrationModal, handleCloseCreateMigrationModal, handleSubmitCreateMigrationModal)}
                <div className="loading_wrapper">
                    <ScaleLoader loading={loading} />
                </div>
            </div>
        </>
    );
}