import Graphql from "../graphql";

class PodQueries {
    static createPod(data) {
        const pushData = async () => {
            const createPod  = `
                mutation CreatePod($input: CreatePodInput!, $condition: ModelPodConditionInput) {
                    createPod(input: $input, condition: $condition) {
                        id
                        podName
                        domain
                        domainUser
                        timeZone
                        podSubId
                        awsAccountId
                        awsRegion
                        multiTimezone
                        vpcId
                        vpcCidr
                        securityGroupFile
                        securityGroupSql
                        securityGroupMgmt
                        securityGroupAD
                        sqlAddress
                        sqlName
                        sqlAz
                        sqlReportAddress
                        sqlReportName
                        sqlReportAz
                        fileServerAddress
                        fileServerName
                        fileServerAz
                        dnsIpPrimary
                        dnsIpSecondary
                        tgwAttachmentId
                        medFusionCidr
                        instanceKeyPair
                    }
                }
            `;

            let variables = {
                input: data
            }
            
            return await Graphql.query(createPod, variables);
        }
        
        return pushData();
    }

    static deletePod(podId,podVersion) {
        const pushData = async () => {
            const deletePod  = `
                mutation DeletePod(
                    $input: DeletePodInput!
                    $condition: ModelPodConditionInput
                ) {
                    deletePod(input: $input, condition: $condition) {
                        id
                        podName
                        domain
                        domainUser
                        timeZone
                        podSubId
                        awsAccountId
                        awsRegion
                        multiTimezone
                        vpcId
                        vpcCidr
                        securityGroupFile
                        securityGroupSql
                        securityGroupMgmt
                        securityGroupAD
                        sqlAddress
                        sqlName
                        sqlAz
                        sqlReportAddress
                        sqlReportName
                        sqlReportAz
                        fileServerAddress
                        fileServerName
                        fileServerAz
                        stacks {
                            nextToken
                            startedAt
                            __typename
                        }
                        dnsIpPrimary
                        dnsIpSecondary
                        tgwAttachmentId
                        medFusionCidr
                        instanceKeyPair
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        __typename
                    }
                }
            `;

            let variables = {
                input: {
                    id: podId,
                    _version: podVersion
                }
            }
            
            return await Graphql.query(deletePod, variables);
        }
        
        return pushData();
    }
    static editPod(data) {
        const pushData = async () => {
            const updatePod  = `
                mutation UpdatePod(
                    $input: UpdatePodInput!
                    $condition: ModelPodConditionInput
                ) {
                    updatePod(input: $input, condition: $condition) {
                        id
                        podName
                        domain
                        domainUser
                        timeZone
                        podSubId
                        awsAccountId
                        awsRegion
                        multiTimezone
                        vpcId
                        vpcCidr
                        securityGroupFile
                        securityGroupSql
                        securityGroupMgmt
                        securityGroupAD
                        sqlAddress
                        sqlName
                        sqlAz
                        sqlReportAddress
                        sqlReportName
                        sqlReportAz
                        fileServerAddress
                        fileServerName
                        fileServerAz
                        dnsIpPrimary
                        dnsIpSecondary
                        tgwAttachmentId
                        medFusionCidr
                        instanceKeyPair
                        _version
                    }
                }
            `;

            let variables = {
                input: data
            }
            
            return await Graphql.query(updatePod, variables);
        }
        
        return pushData();
    }

    static createStack(data) {
        const pushData = async () => {
            const createStack  = `
                mutation CreateStack(
                    $input: CreateStackInput!
                    $condition: ModelStackConditionInput
                ) {
                    createStack(input: $input, condition: $condition) {
                        id
                        stackName
                        amiTermServ
                        amiTermServBase
                        amiAncServ
                        amiPxPServ
                        az1
                        az1Cidr
                        az2
                        az2Cidr
                        dnsIpSecondary
                        tgwAttachmentId
                        medFusionCidr
                        createBaseTermServer
                        createPxpServer
                        instanceTypeTermServ
                        _version
                        clientStacksClientId
                        podStacksId
                    }
                }
            `;

            let variables = {
                input: data
            }
            
            return await Graphql.query(createStack, variables);
        }
        
        return pushData();
    }

}

export default PodQueries;