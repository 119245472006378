import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ScaleLoader } from "react-spinners";
import Auth from "../../../lib/auth";

import Graphql from "../../../lib/graphql";
import Paginate from "../../../lib/paginate";
import Search from "../../../lib/search";
import Queries from "../../../lib/client/queries";
import Modals from "../../../lib/client/modals";

export default function TerminalServersClientList() {
    useEffect(() => {
        if(!Auth.groupCheck('EnvironmentAdmins') && !Auth.groupCheck('MigrationAdmins') && !Auth.groupCheck('MigrationEngineers')) {
            const error = new Error();

            error.message   = 'Not Authorized';
            error.stack     = null;
            error.httpError = 401;

            throw error;
        }
    }, []);

    const navigate                                      = useNavigate();
    const [clients, setClients]                         = useState([]);
    const [visibleClients, setVisibleClients]           = useState([]);
    const [showAddExistingClientModal, setShowAddExistingClientModal]   = useState(false);
    const [loading, setLoading]                         = useState(true);
    const [currentPage, setCurrentPage]                 = useState(1);
    const [totalPages, setTotalPages]                   = useState(1);


    const handleCloseAddExistingClientModal = () => setShowAddExistingClientModal(false);
    const handleShowAddExistingClientModal  = () => setShowAddExistingClientModal(true);

    const pageSize  = 25;

    useEffect(() => {
        const fetchData = async () => {
            const listClients   = `
                query ListClients(
                    $clientId: ID
                    $filter: ModelClientFilterInput
                    $limit: Int
                    $nextToken: String
                    $sortDirection: ModelSortDirection
                ) {
                    listClients(
                      clientId: $clientId
                      filter: $filter
                      limit: $limit
                      nextToken: $nextToken
                      sortDirection: $sortDirection
                    ) {
                        items {
                          clientId
                          clientName
                          userCount
                          timeZone
                          leveraged
                          multiTimezone
                          migrations {
                            items {
                                terminalServers {
                                    items {
                                      instanceId
                                    }
                                }
                            }
                          }
                          stacks {
                            items {
                              pod {
                                id
                                podName
                              }
                            }
                          }
                        }
                        nextToken
                    }
                }
            `;

            let variables = {
                filter: {
                    _deleted: {
                        ne: true
                    }
                }
            }

            await Graphql.queryAllResults(listClients, variables).then((gq) => {
                // Sort by clientName ASC initially
                const sortedClients = gq.listClients.items.sort((a, b) => 
                    a.clientName.localeCompare(b.clientName)
                );

                sortedClients.forEach((client) => {
                    client.totalTerminalServers = client.migrations.items.reduce((total, migration) => {
                        if (!migration || !migration.terminalServers || !migration.terminalServers.items) {
                            return total; // Skip if migration or terminalServers is null
                        }
                        const validTerminalServers = migration.terminalServers.items.filter(
                            (server) => server && server.instanceId // Ensure server is not null and has an instanceId
                        );
                        return total + validTerminalServers.length;
                    }, 0);
                });

                setTotalPages(Paginate.getPageCount(pageSize, sortedClients.length));
                setVisibleClients(Paginate.getPageItems(sortedClients, pageSize));
                setClients(sortedClients);
            });
        };

        fetchData().then(() => setLoading(false));
    }, []);

    const handleNextPage        = () => {
        setCurrentPage(currentPage + 1);
    };
    const handlePreviousPage    = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleChangePage = (event) => {
        Paginate.setActive(event);

        let newPageIndex    = event.target.innerHTML;

        setVisibleClients(Paginate.getPageItems(clients, pageSize, newPageIndex));
        setCurrentPage(newPageIndex);
    };

    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const handleSort = (key) => {
        const direction =
            sortConfig.key === key && sortConfig.direction === 'asc'
                ? 'desc'
                : 'asc';

        setSortConfig({ key, direction });

        const sortedClients = [...clients].sort((a, b) => {
            if (typeof a[key] === 'string' && typeof b[key] === 'string') {
                return direction === 'asc'
                    ? a[key].localeCompare(b[key])
                    : b[key].localeCompare(a[key]);
            }
            return direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
        });

        setVisibleClients(Paginate.getPageItems(sortedClients, pageSize));
    };

    const checkTargetStackAdded = async (client) => {
        try {
            // Ensure stacks exists and is accessible
            const stacks = client.stacks?.items || [];
            if (stacks.length !== 0) {
                navigate(`/terminal-servers/${client.clientId}`);
            } else {
                alert(`The client ${client.clientId} needs to be assigned to a target stack.`);
            }
        } catch (error) {
            console.error('Error checking stacks:', error);
            alert('An error occurred while checking stacks. Please try again.');
        }
    };
    

    function handleSubmitAddExistingClientModal() {
        const form          = document.getElementById("createClient");
        const formData      = new FormData(form);
        const formDataObj   = {};

        formData.forEach((value, key) => formDataObj[key] = value);

        formDataObj.leveraged           = formDataObj.leveraged === "on";
        formDataObj.multiTimezone       = formDataObj.multiTimezone === "on";
        formDataObj.internalSubnets = formDataObj.internalSubnets?.split('\n') || [];
        formDataObj.osVersion = formDataObj.osVersion || null;
        formDataObj.sqlDriveLetter = formDataObj.sqlDriveLetter || null;
        formDataObj.timeZone = formDataObj.timeZone || null;
        
        if (!formDataObj.clientId || !formDataObj.clientName || !formDataObj.userCount) {
            alert('Missing required fields');
            return false;
        }

        return Queries.createClient(formDataObj).then(() => window.location.reload());
    }

    function handleSearch(keywords) {
        let paginationContainer = document.querySelector('.pagination');

        if (keywords.length <= 0) {
            if (paginationContainer) {
                paginationContainer.style.visibility = 'visible';
            }

            return setVisibleClients(clients);
        } else {
            if (paginationContainer) {
                paginationContainer.style.visibility = 'hidden';
            }
        }

        let results = Search.json(keywords, ["clientId", "clientName"], clients);

        return setVisibleClients(results);
    }

    function clientsTable() {
        return (
            <div>
                <table className="lynx-table table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th
                                className={`sortable ${sortConfig.key === 'clientId' ? sortConfig.direction : ''}`}
                                onClick={() => handleSort('clientId')}
                            >
                                Client ID {sortConfig.key === 'clientId' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                            </th>
                            <th
                                className={`sortable ${sortConfig.key === 'clientName' ? sortConfig.direction : ''}`}
                                onClick={() => handleSort('clientName')}
                            >
                                Client Name {sortConfig.key === 'clientName' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                            </th>
                            <th
                                className={`sortable ${sortConfig.key === 'totalTerminalServers' ? sortConfig.direction : ''}`}
                                onClick={() => handleSort('totalTerminalServers')}
                            >
                                Terminal Servers {sortConfig.key === 'totalTerminalServers' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {visibleClients.map((client) => (
                            <tr
                            key={client.clientId}
                            style={{ cursor: 'pointer' }}
                            onClick={() => checkTargetStackAdded(client)}
                        >
                            <td>{client.clientId}</td>
                            <td>{client.clientName}</td>
                            <td>{client.totalTerminalServers}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
        </div>
        );
    }

    return (
        <>
            <h1>
                All Clients
            </h1>

            <hr />
            <div className="mw-100 clearfix">
                <div className="float-start m-2">
                    {Search.getSearchHtml(handleSearch)}
                </div>
                <div className="float-end m-3">
                    <button className="btn btn-success float-end" onClick={handleShowAddExistingClientModal}>
                        Add Existing Client
                    </button>
                    {Modals.addExistingClientModal(showAddExistingClientModal, handleCloseAddExistingClientModal, handleSubmitAddExistingClientModal)}
                </div>
            </div>
            <div className="mw-100 mt-3 clearfix">
                <ul className="pagination justify-content-center">
                    {Paginate.getPaginationHtml(pageSize, currentPage, clients, handleChangePage)}
                </ul>
            </div>
            <div className="mw-100 clearfix">
                {clientsTable()}
                <div className="loading_wrapper">
                    <ScaleLoader loading={loading} />
                </div>
            </div>
        </>
    );
}