import { useEffect, useState } from "react";
import Auth from "../../../lib/auth";
import { useNavigate } from "react-router-dom";
import Graphql from "../../../lib/graphql";
import Paginate from "../../../lib/paginate";
import Search from "../../../lib/search";
import {ScaleLoader} from "react-spinners";

export default function MigrationList() {
    useEffect(() => {
        if(!Auth.groupCheck('EnvironmentAdmins') && !Auth.groupCheck('MigrationAdmins') && !Auth.groupCheck('MigrationEngineers')) {
            const error = new Error();

            error.message   = 'Not Authorized';
            error.stack     = null;
            error.httpError = 401;

            throw error;
        }
    }, []);

    const navigate                                  = useNavigate();
    const [migrations, setMigrations]               = useState([]);
    const [visibleMigrations, setVisibleMigrations] = useState([]);
    const [loading, setLoading]                     = useState(true);
    const [currentPage, setCurrentPage]             = useState(1);
    const [totalPages, setTotalPages]               = useState(1);

    const pageSize  = 25;

    useEffect(() => {
        const fetchData = async () => {
            const listMigrations   = `
                query ListMigrations(
                    $filter: ModelMigrationFilterInput
                    $limit: Int
                    $nextToken: String
                ) {
                    listMigrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
                        items {
                            id
                            dataMigrationRegion
                            migrationName
                            scheduledStart
                            scheduledCutover
                            status
                            useDms
                            createdAt
                            updatedAt
                            _version
                            _deleted
                            _lastChangedAt
                            clientMigrationsClientId
                            migrationCustomerBaselineAMIId
                            __typename
                        }
                        nextToken
                        startedAt
                        __typename
                    }
                }
            `;

            let variables = {
                filter: {
                    _deleted: {
                        ne: true
                    }
                }
            }

            await Graphql.queryAllResults(listMigrations, variables).then((gq) => {

                // sort by migration name ASC. TODO - how can we do this in gql?
                gq.listMigrations.items.sort((a, b) => {
                    const nameA = a.migrationName.toUpperCase();
                    const nameB = b.migrationName.toUpperCase();
                    if (nameA < nameB) { return -1; }
                    if (nameA > nameB) { return 1; }

                    return 0;
                });

                setTotalPages(Paginate.getPageCount(pageSize, gq.listMigrations.items.length));
                setVisibleMigrations(Paginate.getPageItems(gq.listMigrations.items, pageSize));

                return setMigrations(gq.listMigrations.items);
            });
        };

        fetchData().then(() => setLoading(false));
    }, []);

    const handleChangePage = (event) => {
        Paginate.setActive(event);

        let newPageIndex    = event.target.innerHTML;
        setVisibleMigrations(Paginate.getPageItems(migrations, pageSize, newPageIndex));
        setCurrentPage(newPageIndex);
    };

    function handleSearch(keywords) {
        let paginationContainer = document.querySelector('.pagination');

        if (keywords.length <= 0) {
            if (paginationContainer) {
                paginationContainer.style.visibility = 'visible';
            }

            return setVisibleMigrations(migrations);
        } else {
            if (paginationContainer) {
                paginationContainer.style.visibility = 'hidden';
            }
        }

        let results = Search.json(keywords, ["id", "migrationName"], migrations);

        return setVisibleMigrations(results);
    }

    function migrationRows(migrations) {
        let rows    = [];
        for (let i = 0; i < migrations.length; i++) {
            rows.push(
                <tr style={{ cursor: 'pointer' }} onClick={() => navigate(`/migrations/${migrations[i].id}`)} key={migrations[i].id}>
                    <td>{migrations[i].migrationName}</td>
                    <td>{migrations[i].migrationTargetStackId} {/* TODO does the migrationTargetStackId field even exist? not found in schema */}</td>
                    <td>{migrations[i].scheduledStart}</td>
                    <td>{migrations[i].scheduledCutover}</td>
                    <td>{migrations[i].status }</td>
                </tr>
            )
        }

        return (
            <tbody>
                {rows}
            </tbody>
        );
    }

    function migrationsTable() {
        return (
            <table className="lynx-table table table-striped table-hover table-bordered">
                <thead>
                <tr>
                    <th>
                        Migration Name
                    </th>
                    <th>
                        Target Stack ID
                    </th>
                    <th>
                        Scheduled Start
                    </th>
                    <th>
                        Scheduled Cutover
                    </th>
                    <th>
                        Status
                    </th>
                </tr>
                </thead>
                {migrationRows(visibleMigrations)}
            </table>
        );
    }

    return (
        <>
            <h1>
                All Migrations
            </h1>

            <hr />
            <div className="mw-100 clearfix">
                <div className="float-start m-2">
                    {Search.getSearchHtml(handleSearch)}
                </div>
            </div>
            <div className="mw-100 mt-3 clearfix">
                <ul className="pagination justify-content-center">
                    {Paginate.getPaginationHtml(pageSize, currentPage, migrations, handleChangePage)}
                </ul>
            </div>
            <div className="mw-100 clearfix">
                {migrationsTable()}
                <div className="loading_wrapper">
                    <ScaleLoader loading={loading} />
                </div>
            </div>
        </>
    );
}