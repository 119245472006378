import AWS from 'aws-sdk';
import {GetSecretValueCommand, SecretsManagerClient} from "@aws-sdk/client-secrets-manager";

class Utils {
    static async getSecret(key, secretName = null, region = null) {
        const idToken               = localStorage.getItem('idToken');
        const cognitoIdpEndpoint    = 'cognito-idp.' + process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_REGION + '.amazonaws.com/' + process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID;

        AWS.config.region           = process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_REGION;

        AWS.config.credentials      = await new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
            Logins: {
                [cognitoIdpEndpoint] : idToken
            }
        });

        return await AWS.config.credentials.getPromise().then(async () => {
            secretName = secretName ? secretName : 'lynx-frontend/' + process.env.REACT_APP_LYNX_ENVIRONMENT;

            const client = new SecretsManagerClient({
                region: region ? region : process.env.REACT_APP_AWS_SECRETS_MANAGER_REGION,
                credentials: {
                    accessKeyId: AWS.config.credentials.accessKeyId,
                    secretAccessKey: AWS.config.credentials.secretAccessKey,
                    sessionToken: AWS.config.credentials.sessionToken
                }
            });

            let response;

            try {
                response = await client.send(
                    new GetSecretValueCommand({
                        SecretId: secretName,
                        VersionStage: "AWSCURRENT",
                    })
                );
            } catch (error) {
                return console.error(error);
            }

            const secret = typeof response.SecretString !== "undefined" ? response.SecretString : null;

            return secret ? Utils.getValueFromSecretString(secret, key) : null;
        });
    }

    static getValueFromSecretString(secretString, objKey) {
        try {
            let jsonSecretString = JSON.parse(secretString);

            if (typeof jsonSecretString === "object") {
                return jsonSecretString[objKey];
            }
        } catch (e) {
            return secretString;
        }
    }

    static nl2br(string) {
        return string.toString().replace(/\n/g, "<br>");
    }

    static formatRegion(region) {
        if (!region) return
        return region.replaceAll('_', '-')
    }
}

export default Utils;